import { DatePipe } from "@angular/common";
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CreateDocumentData } from "@api/documents/models/create-document.model";
import {
  DocumentData,
  DocumentSignature,
  DocumentTag,
} from "@api/documents/models/document.model";
import { DocumentMapperService } from "@api/documents/services/document-mapper.service";
import { environment } from "@environment/environment";
import { PageData } from "@modules/shared/models/page.model";
import { ValueList } from "@modules/shared/models/value-list.model";
import { Observable, concat, of } from "rxjs";
import { map, pairwise } from "rxjs/operators";
import { DocumentLink } from "../models/document-link.model";
import { LinkResourceType } from "../models/link-resource-type.model";
import { LinkResource } from "../models/link-resource.model";
import { DocumentCategory } from "../models/document-category.model";
import { ShareDocumentData } from "../models/share-document.model";
import { SharedEntity } from "../models/shared-entity.model";
import { DocumentCreateStatus } from "../types/document-create-status";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

export interface UpdateDocumentData {
  name: string;
  expiration_date: moment.Moment | string;
  document_type_id: number;
  number: string;
  tags: string[];
  country_code?: string;
  service_category_id?: number;
}

@Injectable()
export class DocumentService {
  private entityType: string;
  private entityId: number;
  private caseType?: {
    case_type: string;
    case_type_id: string;
  };
  constructor(
    private http: HttpClient,
    private readonly documentMapperService: DocumentMapperService,
    private datePipe: DatePipe
  ) {}

  public categories(): Observable<DocumentCategory[]> {
    return this.http
      .get<{ result: DocumentCategory[] }>(
        `${environment.gateway_endpoint}documents/categories`
      )
      .pipe(map(({ result }) => result));
  }

  public export(url: string, data): Observable<Blob> {
    const withoutFilters = { ...data, url: url };
    const parseParams = (data) => {
      let params: HttpParams = new HttpParams();
      for (const key of Object.keys(data)) {
        if (data[key]) {
          if (data[key] instanceof Array) {
            params = params.append(key, data[key].join(";"));
          } else {
            params = params.append(key.toString(), data[key]);
          }
        }
      }
      return params;
    };

    return this.http.get(environment.gateway_endpoint + "documents/export", {
      params: parseParams(withoutFilters),
      responseType: "blob",
    });
  }

  public stopSharing(
    documentId: number,
    userId: number
  ): Observable<DocumentData> {
    return this.http
      .post(
        environment.gateway_endpoint + `documents/` + documentId + "/share",
        {
          user_ids: [userId],
        }
      )
      .pipe(
        map((response: any) =>
          this.documentMapperService.mapOne(response.result.document)
        )
      );
  }

  public linkedWithList(docId: number) {
    return this.http.get(
      environment.gateway_endpoint + "documents/" + docId + "/link"
    );
  }

  public unlink(
    docId: number,
    linkId: number
  ): Observable<{ success: boolean }> {
    return this.http.delete<{ success: boolean }>(
      environment.gateway_endpoint + "documents/" + docId + "/link/" + linkId
    );
  }

  public linkedList(docId: number): Observable<DocumentLink[]> {
    return this.http
      .get<{ result: { items: DocumentLink[] } }>(
        environment.gateway_endpoint + "documents/" + docId + "/link"
      )
      .pipe(map(({ result }) => result.items));
  }

  public storeLink(data: {
    resource_type: string;
    resource_id: number[];
    document_ids: number[];
  }): Observable<DocumentLink[]> {
    return this.http
      .post<{ result: { items: DocumentLink[] } }>(
        environment.gateway_endpoint +
          "documents/" +
          data.document_ids[0] +
          "/link",
        data
      )
      .pipe(map(({ result }) => result.items));
  }

  public sharedWithList(
    docId: number,
    entityType: string,
    entityId: number
  ): Observable<SharedEntity[]> {
    return this.http
      .get<{ result: SharedEntity[]; success: boolean }>(
        environment.gateway_endpoint + "documents/" + docId + "/access",
        { params: { entity_type: entityType, entity_id: entityId } }
      )
      .pipe(map((result) => result.result));
  }

  public update(docId: number, payload: UpdateDocumentData) {
    return this.http.put(
      environment.gateway_endpoint + "documents/" + docId,
      payload
    );
  }

  public downloadFile(
    docId: number,
    fileId: number
  ): Observable<{ result: string; success: boolean }> {
    return this.http.get<{ result: string; success: boolean }>(
      environment.gateway_endpoint +
        "documents/" +
        docId +
        "/files/" +
        fileId +
        "/download"
    );
  }

  public uploadFile(
    docId: number,
    files: File[]
  ): Observable<{ result: { document: DocumentData[] }; success: boolean }> {
    const formData = new FormData();

    files.forEach((file: File) => {
      formData.append("file[]", file, file.name);
    });

    return this.http.post<{
      result: { document: DocumentData[] };
      success: boolean;
    }>(
      environment.gateway_endpoint + "documents/" + docId + "/files",
      formData
    );
  }

  public deleteFile(
    docId: number,
    fileId: number
  ): Observable<{ message: string; success: boolean }> {
    return this.http.delete<{ message: string; success: boolean }>(
      environment.gateway_endpoint + "documents/" + docId + "/files/",
      { params: { "file_ids[]": [fileId] } }
    );
  }

  //todo: interface response type
  public preview(id: number): Observable<DocumentData> {
    return this.http
      .get<any>(environment.gateway_endpoint + "documents/" + id)
      .pipe(map((res) => ({ ...res.result.document })));
  }

  public list(
    entityType: string,
    entityId: string,
    data: ValueList<any> = {}
  ): Observable<PageData<DocumentData>> {
    const withoutFilters = { ...data };

    if (!data.include) {
      data.include = "filters;details";
    }
    const parseParams = (data) => {
      let params: HttpParams = new HttpParams();
      for (const key of Object.keys(data)) {
        if (data[key]) {
          if (data[key] instanceof Array) {
            params = params.append(key, data[key].join(";"));
          } else {
            params = params.append(key.toString(), data[key]);
          }
        }
      }
      return params;
    };

    return concat(
      of({}),
      this.http.get<any>(
        environment.gateway_endpoint +
          `documents/` +
          entityType +
          "/" +
          entityId,
        {
          params: parseParams(withoutFilters),
        }
      ),
      this.http.get<any>(
        environment.gateway_endpoint +
          `documents/` +
          entityType +
          "/" +
          entityId,
        {
          params: parseParams(data),
        }
      )
    ).pipe(
      map((data) => data?.result),
      pairwise(),
      map(([prev, curr]) => ({ ...prev, ...curr })),
      map((data: any) => {
        const { items, ...pageData } = data;
        return {
          ...pageData,
          items: this.documentMapperService.mapMany(items),
        } as PageData<DocumentData>;
      })
    );
  }

  public linkResources(
    docId: number,
    sourceType: string,
    sourceId: number,
    resourceType: string,
    { searchText }: { searchText: string }
  ): Observable<LinkResource[]> {
    return this.http
      .get<{
        success: boolean;
        result: { items: LinkResource[] };
      }>(`${environment.gateway_endpoint}documents/${docId}/link/resources`, {
        params: {
          source_type: sourceType,
          source_id: sourceId,
          resource_type: resourceType,
          search_text: searchText,
        },
      })
      .pipe(map(({ result }) => result.items));
  }

  public linkResourceTypes(
    docId: number,
    source: string
  ): Observable<LinkResourceType[]> {
    return this.http
      .get<{ success: boolean; result: LinkResourceType[] }>(
        `${environment.gateway_endpoint}documents/${docId}/link/resource-types`,
        {
          params: {
            source,
          },
        }
      )
      .pipe(map(({ result }) => result));
  }

  public create(
    entityType,
    entityId,
    data: Partial<CreateDocumentData>,
    reqBody = null,
    reportProgress: boolean = false
  ): Observable<DocumentData | DocumentCreateStatus> {
    data = this.documentMapperService.prepareCreate(data);
    const formData: FormData = new FormData();

    if (data.files && data.files.length > 0) {
      for (const file of data.files) {
        formData.append("file[]", file);
      }
    }
    if (data.number) {
      formData.append("number", data.number);
    }
    if (data.name) {
      formData.append("name", data.name);
    }
    if (data.expiration_date) {
      formData.append(
        "expiration_date",
        this.datePipe.transform(data.expiration_date, "yyyy-MM-dd")
      );
    }
    if (data.document_type_id) {
      formData.append("document_type_id", data.document_type_id);
    }
    if (data.tags && data.tags.length > 0) {
      for (const tag of data.tags) {
        formData.append("tags[]", tag);
      }
    }
    if (reqBody) {
      formData.append("case_type", "task");
      formData.append("case_type_id", reqBody.entity_id);
    }

    if (data.country_code) {
      formData.append("country_code", data.country_code);
    }

    if (data.service_category_id) {
      formData.append("service_category_id", String(data.service_category_id));
    }

    if (data.multiple) {
      formData.append("multiple", data.multiple.toString());
    }

    if (data.docs_count && data.docs_count > 0) {
      formData.append("docs_count", String(data.docs_count));
    }

    if (data.status_id) {
      formData.append("status_id", String(data.status_id));
    }

    const req = new HttpRequest(
      "POST",
      environment.gateway_endpoint + `documents/` + entityType + "/" + entityId,
      formData,
      {
        reportProgress,
      }
    );

    return this.http.request(req).pipe(
      map((event: HttpEvent<{ result: { document: DocumentData } }>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            return "sent" as DocumentCreateStatus;

          case HttpEventType.UploadProgress:
            return "in_progress" as DocumentCreateStatus;

          case HttpEventType.Response:
            return this.documentMapperService.mapOne(
              event.body.result.document
            );
        }
      })
    );
  }

  public revoke(
    documentId: number,
    data: { entity_id: number; entity_type: string }
  ) {
    return this.http.delete(
      `${environment.gateway_endpoint}documents/${documentId}/revokeShare`,
      { params: data }
    );
  }

  public share(data: ShareDocumentData): Observable<DocumentData> {
    data = this.documentMapperService.prepareShare(data);

    return this.http
      .post(
        environment.gateway_endpoint +
          `documents/${data.document_ids[0]}/share`,
        data
      )
      .pipe(
        map((response: any) =>
          this.documentMapperService.mapOne(response.result.document)
        )
      );
  }

  // Get Tags
  public tags(): Observable<DocumentTag> {
    return this.http.get<any>(environment.gateway_endpoint + "tags");
  }

  // Get eligible Users
  public eligibleUsers(
    documentId: string,
    params: object
  ): Observable<DocumentTag> {
    return this.http.post<any>(
      environment.gateway_endpoint +
        `documents/` +
        documentId +
        "/eligible-users",
      params
    );
  }

  public sharedEligibleUsers(
    documentId: number,
    entityType: string,
    entityId: string
  ): Observable<{
    result: { entity_id: number; entity_type: string; company_name: string }[];
  }> {
    return this.http.get<{
      result: {
        entity_id: number;
        entity_type: string;
        company_name: string;
      }[];
    }>(
      environment.gateway_endpoint +
        `documents/${entityType}/${entityId}/documents/${documentId}/eligible-entities`
    );
  }

  // Get Doc types
  public types(entityType: string): Observable<DocumentType> {
    return this.http.get<DocumentType>(
      environment.gateway_endpoint + `documents/` + entityType + "/types"
    );
  }

  // Get Doc actions
  getStatusTransitions(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + `documents/entity/actions`
    );
  }

  // Download doc
  downloadDocument(docIds: string[]): Observable<{ result: string }> {
    let params = new HttpParams();

    docIds.forEach((id) => {
      params = params.append("document_ids[]", id);
    });

    return this.http.get<{ result: string }>(
      environment.gateway_endpoint + "documents/download/file",
      { params }
    );
  }

  // Update Status Bulk
  updateStatusBulk(action_id, document_ids): Observable<any> {
    const data = {
      document_ids: document_ids,
      action: action_id,
      case_type_id: "",
      case_type: "",
    };

    if (this.caseType) {
      data.case_type = this.caseType.case_type;
      data.case_type_id = this.caseType.case_type_id;
    }
    return this.http.patch<any>(
      environment.gateway_endpoint +
        `documents/` +
        this.entityType +
        `/` +
        this.entityId,
      data,
      httpOptions
    );
  }

  deleteDocument(
    id
  ): Observable<{ message: string; success: boolean; result: [] }> {
    return this.http
      .delete<{ message: string; success: boolean; result: [] }>(
        environment.gateway_endpoint + "files/" + id,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  setEntityData(
    entityType: string,
    entityId: number,
    type?: {
      case_type: string;
      case_type_id: string;
    }
  ) {
    this.entityType = entityType;
    this.entityId = entityId;
    if (type) {
      this.caseType = {
        case_type: type.case_type,
        case_type_id: type.case_type_id,
      };
    }
  }

  getExpiredDocs(
    filters: Partial<ValueList<string>>
  ): Observable<PageData<DocumentData>> {
    return this.http
      .get<{ result: PageData<DocumentData> }>(
        `${environment.gateway_endpoint}documents/statistics`,
        {
          params: filters,
        }
      )
      .pipe(
        map((response) => {
          const data = response.result;
          data.items = this.documentMapperService.mapMany(data.items);
          return data;
        })
      );
  }

  archiveExpiredDocument(
    action_id: number,
    document_ids: number[]
  ): Observable<any> {
    const data = {
      document_ids: document_ids,
      action: action_id,
      case_type_id: "",
      case_type: "",
    };

    if (this.caseType) {
      data.case_type = this.caseType.case_type;
      data.case_type_id = this.caseType.case_type_id;
    }
    return this.http.patch<any>(
      environment.gateway_endpoint + `documents`,
      data
    );
  }

  public createSignature(
    params: DocumentSignature
  ): Observable<DocumentSignature> {
    return this.http.post<any>(
      environment.gateway_endpoint +
        `documents/` +
        params.document_id +
        "/signature",
      params
    );
  }

  public updateSignature(
    params: DocumentSignature
  ): Observable<DocumentSignature> {
    return this.http.put<any>(
      environment.gateway_endpoint +
        `documents/` +
        params.document_id +
        "/signature/" +
        params.id,
      params
    );
  }

  public listSignature(params): Observable<DocumentSignature[]> {
    return this.http
      .get<any>(
        environment.gateway_endpoint +
          `documents/` +
          params.document_id +
          "/signature"
      )
      .pipe(
        map((response) => {
          return response.result;
        })
      );
  }

  public signature(params): Observable<DocumentSignature> {
    return this.http
      .get<any>(
        environment.gateway_endpoint +
          `documents/` +
          params.document_id +
          "/signature"
      )
      .pipe(
        map((response) => {
          return response.result[0];
        })
      );
  }

  public docusignConnect(code: string) {
    return this.http.post<any>(
      environment.gateway_endpoint + `docusign/callback`,
      { code: code }
    );
  }
}
