import * as enabledPages from "./enabled-pages";

const stringToBoolean = (stringValue): boolean => {
  return ["true", "yes", "1"].includes(`${stringValue}`?.toLowerCase()?.trim());
};

const environment = {
  production: stringToBoolean(process.env.NG_PRODUCTION),
  admin_portal_url:
    process.env.NG_ADMIN_PORTAL_URL || process.env.NG_ADMIN_PORTAL,
  provider_portal_url: process.env.NG_PROVIDER_PORTAL_URL,
  gateway_endpoint: process.env.NG_GATEWAY_ENDPOINT,
  drupal_nestlers_url: process.env.NG_DRUPAL_NESTLERS_URL,
  marketplace_url: process.env.NG_MARKETPLACE_URL,
  client_id: process.env.NG_CLIENT_ID,
  client_secret: process.env.NG_CLIENT_SECRET,
  marketplace_domain: process.env.NG_MARKETPLACE_DOMAIN,
  pusher_key: process.env.NG_PUSHER_KEY,
  wsHost: process.env.NG_WS_HOST,
  wsPort: process.env.NG_WS_PORT,
  google_tracking_id: process.env.NG_GOOGLE_TRACKING_ID,
  expat_terms_url: process.env.NG_EXPAT_TERMS_URL,
  expat_terms_version: process.env.NG_EXPAT_TERMS_VERSION,
  stripe_public_key: process.env.NG_STRIPE_PUBLIC_KEY,
  stripe_portal_login: process.env.NG_STRIPE_PORTAL_LOGIN,
  enabled_pages: process.env.NG_ENABLED_PAGES
    ? enabledPages[process.env.NG_ENABLED_PAGES]
    : undefined,
  docusign_client_id: process.env.DOCUSIGN_CLIENT_ID,
  docusign_base_path: process.env.DOCUSIGN_BASE_PATH,
  docusign_app_path: process.env.DOCUSIGN_APP_PATH,
};
export const processEnvironment = Object.keys(environment).reduce(
  (result, key) => {
    if (environment[key] !== null && environment[key] !== undefined) {
      result[key] = environment[key];
    }
    return result;
  },
  {}
);
